import React from "react";
import styled from "styled-components";

import TeamCard from "../components/TeamCard";

import member1 from "../assets/img/team/1.png";
import member2 from "../assets/img/team/2.png";
import member3 from "../assets/img/team/3.png";
import member4 from "../assets/img/team/4.png";

const members = [
  {
    job: "THE ASTRO",
    img: member4,
    role: "Invented the project, built a team to work together with, and runs the discord.",
  },
  {
    job: "STROM DESIGNER",
    img: member1,
    role: "Leading a team of both full time artists and flex workers,",
  },
  {
    job: "BOOST CODER",
    img: member2,
    role: "Leading a team to develop the website and the tools.",
  },
  {
    job: "FLASH MARKETING ",
    img: member3,
    role: "Runs the Social Media page and marketing campaigns, and organizes marketing.",
  },
];

const Team = () => {
  return (
    <Style id="team">
      <div className="container">
        <h2>Team</h2>
        <div>
          {members.map((member, index) => (
            <TeamCard img={member.img} job={member.job} role={member.role} key={index} />
          ))}
        </div>
      </div>
    </Style>
  );
};

export default Team;

const Style = styled.section`
  margin-bottom: 150px;

  h2 {
    text-align: center;
    margin-bottom: clamp(50px, 7vw, 90px);
  }
  .container {
    > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      > * {
        margin-bottom: clamp(40px, 5vw, 70px);
        :nth-child(2n + 1) {
          margin-right: 40px;
        }
      }
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        > * {
          :nth-child(2n + 1) {
            margin-right: 0;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 70px;
  }
`;
