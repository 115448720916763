import React from "react";
import styled from "styled-components";

import project1 from "../assets/img/project1.svg";
import ProjectCard from "../components/ProjectCard";

const projects = [
  {
    img: "https://orcs.mypinata.cloud/ipfs/QmXnm6anmNwumLz2fNxqp88iQwgdVW9NYUmvZmEDVnf1JV",
    name: "Billionaire Bigfoot Club",
    details:
      "The Billionaire Bigfoot Club is a genesis collection of 3,333 adorable wealthy bigfoots living in the Ethereum blockchain,launching on Immutable X (ETH with no gas fees).",
  },
  // {
  //   name: "METATAIL",
  //   img: project1,
  //   details:
  //     "Proin quis diam magna. Ut dapibus aliquam ante, at facilisis dui suscipit ac. Donec vehicula dui sem, at egestas leo rhoncus ac. Aliquam a tellus congue, consectetur augue vitae, sollicitudin mi. Praesent at mi gravida, vestibulum turpis eget, porta lectus. Maecenas quis consequat purus, ut congue ipsum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas est elit, feugiat sit amet hendrerit at, fermentum non sem.",
  // },
  // {
  //   name: "XONEBOX",
  //   img: project1,
  //   details:
  //     "Proin quis diam magna. Ut dapibus aliquam ante, at facilisis dui suscipit ac. Donec vehicula dui sem, at egestas leo rhoncus ac. Aliquam a tellus congue, consectetur augue vitae, sollicitudin mi. Praesent at mi gravida, vestibulum turpis eget, porta lectus. Maecenas quis consequat purus, ut congue ipsum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas est elit, feugiat sit amet hendrerit at, fermentum non sem.",
  // },
];

const Projects = () => {
  return (
    <Style id="projects">
      <div className="container">
        <h2>UPCOMING PROJECTS</h2>
        <div>
          {projects.map((project, index) => (
            <ProjectCard img={project.img} job={project.name} role={project.details} key={index} />
          ))}
        </div>
      </div>
    </Style>
  );
};

export default Projects;

const Style = styled.section`
  margin-bottom: 150px;

  .container {
    h2 {
      text-align: center;
      margin-bottom: clamp(50px, 7vw, 90px);
    }
    > div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      > article {
        :not(:last-child) {
          margin-right: 3vw;
          margin-bottom: 30px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 70px;
  }
`;
