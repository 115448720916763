import React from "react";
import styled from "styled-components";
import Button from "../components/Button";
import { ReactComponent as AboutImage } from "../assets/img/illustrations/about.svg";

const About = () => {
  return (
    <Style id="about">
      <div className="container">
        <div data-aos="fade-up">
          <AboutImage />
        </div>
        <div data-aos="fade-up">
          <Content>
            <h2>ABOUT</h2>
            <p>
              Affinity Launchpad is a platform where we help artists launch their NFT's on several
              blockchain platforms, we always stay up to date with new technology and offer the most
              support in terms of marketing and development.
            </p>
            <Button to="/mint">LAUNCH YOUR NFT</Button>
          </Content>
        </div>
      </div>
    </Style>
  );
};

export default About;

const Style = styled.section`
  margin-bottom: 150px;
  .container {
    display: flex;
    align-items: center;
    > * {
      :nth-child(1) {
        flex: 1;
        position: relative;
        ::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 310px;
          height: 310px;
          background: #ff6ef9;
          filter: blur(300px);
          border-radius: 100px;
        }
        @media (max-width: 768px) {
          svg {
            max-width: 70%;
            margin: 0 auto;
          }
        }
      }
      :nth-child(2) {
        flex: 2;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      text-align: center;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 70px;
  }
`;

const Content = styled.div`
  background: linear-gradient(180deg, #1e1552 0%, rgba(30, 21, 82, 0) 100%);
  border-radius: 10px;
  padding: 93px 65px 134px;
  margin-left: clamp(20px, 3vw, 145px);

  p {
    margin-top: 25px;
    margin-bottom: 38px;
  }
  @media (max-width: 768px) {
    padding: 50px 30px 134px;
    margin-left: 0;
  }
`;
