import React from "react";
import styled from "styled-components";

import { ReactComponent as Eth } from "../assets/img/tokens/eth.svg";
import { ReactComponent as Solana } from "../assets/img/tokens/solana.svg";
import Immutable from "../assets/img/tokens/immutable.svg";

const Tokens = () => {
  return (
    <Style data-aos="fade-up">
      <div className="container">
        <div>
          <Eth />
          <Solana />
          <img src={Immutable} alt="" />
        </div>
      </div>
    </Style>
  );
};

export default Tokens;

const Style = styled.section`
  margin-bottom: 150px;

  .container {
    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      > * {
        margin: 12px 50px 12px;
      }
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 70px;
  }
`;
