import React from "react";
import styled from "styled-components";

import { Brand } from "./Navbar";

const Footer = () => {
  return (
    <Style data-aos="fade-up">
      <div className="container">
        <p className="poweredBy">Powered by</p>
        <Brand />
        <p>@ Copyright Affinity Launchpad 2021</p>
      </div>
    </Style>
  );
};

export default Footer;
const Style = styled.footer`
  background-color: #010007;
  padding: 80px 15px;
  color: #eeeeee;
  text-align: center;

  a {
    flex-direction: column;
    display: inline-flex;
    margin: 30px auto;
    svg {
      margin: 0 0 20px;
    }
  }
  @media (max-width: 768px) {
    p {
      :nth-of-type(1) {
        font-size: 16px;
      }
      :nth-of-type(2) {
        font-size: 14px;
      }
    }
  }
`;
