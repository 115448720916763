import React from "react";
import styled from "styled-components";
import Button from "../components/Button";

import { ReactComponent as HeroImage } from "../assets/img/illustrations/hero.svg";

const Hero = () => {
  return (
    <Style>
      <div className="container">
        <div data-aos="fade-up">
          <h1>WELCOME TO AFFINITY LAUNCHPAD</h1>
          <Button to="/mint">LAUNCH YOUR NFT</Button>
        </div>
        <div data-aos="fade-up">
          <HeroImage />
        </div>
      </div>
    </Style>
  );
};

export default Hero;

const Style = styled.header`
  margin-bottom: 150px;
  padding-top: 190px;
  @media (max-width: 768px) {
    padding-top: 150px;
  }
  .container {
    display: flex;
    align-items: center;
    > * {
      flex: 1;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      text-align: center;
    }
  }

  button {
    margin-top: 60px;
  }
  @media (max-width: 768px) {
    margin-bottom: 70px;

    button {
      margin-top: 70px;
    }
  }
`;
