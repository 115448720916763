import React from "react";

// layouts
import Navbar from "../layout/Navbar";
import Hero from "../layout/Hero";
import About from "../layout/About";
import Projects from "../layout/Projects";
import Tokens from "../layout/Tokens";
import Team from "../layout/Team";
import CTA from "../layout/CTA";

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <About />
      <Projects />
      <Tokens />
      {/* <Team /> */}
      <CTA />
    </>
  );
};

export default Home;
