import React from "react";
import { useState } from "react";
import styled from "styled-components";
import Button from "../components/Button";
import State from "./State";
import axios from "axios";

// const data = [
//   {
//     txType: "IMX",
//     address: "34a750d844d6f6da7fb909375ed03c4521018261",
//     quantity: 10,
//     status: false,
//   },
//   {
//     txType: "IMX",
//     address: "2aa750d844d6f6da7fb909375ed03c4521018261",
//     quantity: 20,
//     status: true,
//   },
//   {
//     txType: "ETH",
//     address: "2ba750d844d6f6da7fb909375ed03c4521018261",
//     quantity: 11,
//     status: false,
//   },
//   {
//     txType: "ETH",
//     address: "2bc750d844d6f6da7fb909375ed03c4521018264",
//     quantity: 23,
//     status: true,
//   },
//   {
//     txType: "ETH",
//     address: "2aa750d84576f6da7fb909375ed03c4521018267",
//     quantity: 12,
//     status: false,
//   },
//   {
//     txType: "IMX",
//     address: localStorage.getItem("address"),
//     quantity: 12,
//     status: false,

//   },
// ];


const History = () => {
  

  const getTx = () => { 

    axios.get(`${process.env.REACT_APP_URL}/api/v1/tx/${localStorage.getItem("address")}`)
    .then((_data)=> {
      console.log(_data)
      setData(_data.data)
      return _data.data
      
    })
  }

 

  const [data,setData] = useState([])



  return (
    <div className="table-container">

      {
        data.length==0 ? 
        
        <Style>
      <h2>TX HISTORY</h2>
      <div className="holder" data-aos="fade-up">
        <Table>
       
         
        
         <h3> Load Transactions</h3>
         <Img onClick={getTx} src="https://img.icons8.com/ios-glyphs/30/ffffff/refresh--v1.png"/>
        </Table>
      </div>
      {/* <PaginationNb pages="7" /> */}
    </Style>
        
        
        
        :

        <Style>
          
        <h2>TX HISTORY</h2>
        
        <div className="holder" data-aos="fade-up">
        
          <Table>
          
            <tr>
           
              <th>TX Type</th>
              <th>TX Hash</th>
              <th>Quantity</th>
              <th>Status</th>
           
              <Img onClick={getTx} src="https://img.icons8.com/ios-glyphs/30/ffffff/refresh--v1.png"/>
        
            </tr>
            
            {data.map((item, index) => (
              <tr>
     
                <td>{item.type}</td>
                <td>{item.hash}</td>
                <td>{item.quantity}</td>
                <td>
                  {item.status}
                </td>
              </tr>
            ))}
            
          </Table>
        </div>
        {/* <PaginationNb pages="7" /> */}
      </Style>

        
      }
          
    </div>

  );
};

export default History;

const Style = styled.div`
  margin-bottom: 200px;
  h2 {
    margin-bottom: clamp(50px, 5vw, 80px);
  }
  .holder {
    background: linear-gradient(180deg, #1e1552 0%, rgba(30, 21, 82, 0) 100%);
    border-radius: 10px;
    padding: 50px;
    margin-bottom: 50px;
    overflow: auto;

    /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #251470;
      border-radius: 4px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #ff6d36;
      border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #c44415;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 80px;
  }
`;





const Table = styled.table`
  width: 100%;
  text-align: center;

  th {
    font-weight: bold;
    font-size: 20px;
    font-family: "Montserrat Bold";
    color:white
  }

  tr
  { 
    color:white
  }
  tr {
    td,
    th {
      padding: 20px;
      :nth-child(2) {
        text-align: left;
        padding-left: 100px;
        color:white;
      }
    }
  }
`;



const Img = styled.img`

height:30px;


:hover 
{ 
  cursor:pointer;


}

`