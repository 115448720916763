import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Button from "../components/Button";
import { ReactComponent as Logo } from "../assets/img/logo/brand.svg";
import { ReactComponent as CloseIcon } from "../assets/img/icons/close.svg";
import { ReactComponent as Menu } from "../assets/img/icons/menu.svg";

const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <Style className={scroll ? "scroll" : ""}>
      <div className="container">
        <Brand />
        <Links className={menu ? "show" : ""}>
          {menu && (
            <>
              <Brand />
              <button className="close" onClick={() => setMenu(false)}>
                <CloseIcon />
              </button>
            </>
          )}
          <div>
            <a href="#about" onClick={() => setMenu(false)}>
              About
            </a>
            <a href="#projects" onClick={() => setMenu(false)}>
              Projects
            </a>
            <a href="#team" onClick={() => setMenu(false)}>
              Teams
            </a>
          </div>
          <Button variant="secondary" to="/mint">
            LAUNCH YOUR NFT
          </Button>
        </Links>
        <Hamburger>
          <button onClick={() => setMenu(true)}>
            <Menu />
          </button>
        </Hamburger>
      </div>
    </Style>
  );
};

export default Navbar;

export const Brand = () => {
  return (
    <BrandStyle to="/">
      <Logo className="brand" />
      <b>
        Affinity <span>launchpad</span>
      </b>
    </BrandStyle>
  );
};

const BrandStyle = styled(Link)`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: clamp(16px, 2vw, 30px);
  font-weight: bold;
  font-family: "Montserrat Bold";
  text-transform: Capitalize;

  svg {
    width: 63px;
    min-width: 36px;
    margin-right: clamp(15px, 2.5vw, 40px);
    /* Add transition to logo on scroll */
    transition: width 200ms;
  }

  span {
    background: linear-gradient(90.66deg, #5e44ff 8.53%, #c76bff 94.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 4px;
    font-size: clamp(16px, 2vw, 30px);
    font-weight: bold;
    font-family: "Montserrat Bold";
  }
  @media (max-width: 768px) {
    flex: 2;
    svg {
      width: 37px !important;
      height: 45px;
    }
  }
`;

const Style = styled.nav`
  z-index: 10;
  position: fixed;
  top: 45px;
  padding-top: 15px;
  padding-bottom: 15px;
  left: 0;
  right: 0;
  width: 100%;
  transition: top 200ms, background-color 200ms;

  // on Scrole
  &.scroll {
    top: 0;
    background: linear-gradient(169.52deg, #0b004f 0.33%, rgba(47, 10, 70, 0.92) 99.02%);
    transition: top 200ms, background 200ms;
    .brand {
      width: 50px;
      transition: width 200ms;
    }
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: row-reverse;
      justify-content: center;
    }
  }
  @media (max-width: 768px) {
    top: 0;
  }
`;

const Links = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: clamp(15px, 4vw, 100px);
  transition: left 200ms;

  a {
    color: #fff;
    :hover {
      background: linear-gradient(to right, #ffa24d -2%, #ff6332 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: background 200ms;
    }
  }
  button {
    margin-left: auto;
    &.close {
      position: fixed;
      top: 35px;
      right: 35px;
    }
  }
  > div {
    display: flex;
    align-items: center;

    a {
      padding: 5px 15px;
      margin-right: 5px;
    }
  }
  .close {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
  }
  @media (max-width: 768px) {
    padding-top: 72px;
    padding-left: 15px;
    padding-right: 15px;
    position: fixed;
    inset: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    margin-left: 0;
    background: linear-gradient(169.52deg, #0b004f 0.33%, rgba(47, 10, 70, 0.92) 99.02%);
    flex-direction: column;
    align-items: center;
    &.show {
      transition: left 200ms;
      left: 0;
    }
    > a {
      flex: 0;
    }
    button {
      margin-left: 0;
    }
    > div {
      margin-top: 40px;
      margin-bottom: 60px;
      width: 80%;
      display: flex;
      flex-direction: column;

      a {
        text-align: center;
        width: 100%;
        padding: 10px;
        margin-right: 0;
        margin-bottom: 15px;
      }
    }
  }
`;

const Hamburger = styled.div`
  flex: 0.6;
  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;
