import React from "react";
import styled from "styled-components";

// Layout
import { Brand } from "../layout/Navbar";
import Header from "../layout/Mint";
import Table from "../components/Table";

const Mint = () => {
  return (
    <>
      <Navbar>
        <Brand />
      </Navbar>
      <div className="container">
        <Header />
        <Table />
      </div>
    </>
  );
};

export default Mint;

const Navbar = styled.nav`
  padding-top: clamp(30px, 5vw, 80px);
  padding-bottom: clamp(50px, 7vw, 100px);
  display: flex;
  justify-content: center;
  a {
    display: inline-flex;
    flex: initial;
  }
`;
