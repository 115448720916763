import React from "react";
import styled from "styled-components";
import Button from "../components/Button";

const CTA = () => {
  return (
    <Style>
      <div className="container">
        <Content data-aos="fade-up">
          <h2>STARTING LAUNCH YOUR NFT WITH US</h2>
          <Button to="/mint">LAUNCH YOUR NFT</Button>
        </Content>
      </div>
    </Style>
  );
};

export default CTA;

const Style = styled.section`
  margin-bottom: 200px;
  @media (max-width: 768px) {
    margin-bottom: 90px;
  }
`;

const Content = styled.div`
  background: linear-gradient(90.76deg, #5e44ff 9.84%, #c76bff 92.83%);
  border-radius: 10px;
  padding: 130px 65px 130px 105px;
  display: flex;
  align-items: center;
  h2 {
    font-weight: 800;
    font-size: 60px;
    flex: 3;
    margin-right: 20px;
  }
  button {
    flex: 1;
  }
  @media (max-width: 768px) {
    padding: 50px 30px 70px 30px;
    flex-direction: column;
    text-align: center;
    h2 {
      font-size: 30px;
      margin-bottom: 30px;
      margin-right: 0px;
    }
  }
`;
