import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AOS from "aos";

// Pages
import Home from "./pages/Home";
import Mint from "./pages/Mint";
import NotFound from "./pages/NotFound";

import Footer from "./layout/Footer";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      disable: "mobile",
    });
  }, []);
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/bbc/mint" element={<Mint />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
