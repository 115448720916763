import React from "react";
import styled from "styled-components";

export default function Card({ img, job, role }) {
  return (
    <CardStyle data-aos="fade-up">
      <div className="imgHolder">
        <img src={img} alt="" />
      </div>
      <div className="details">
        <h4>{job}</h4>
        <p className="job">{role}</p>
      </div>
    </CardStyle>
  );
}

const CardStyle = styled.div`
  background: linear-gradient(180deg, #1e1552 0%, rgba(30, 21, 82, 0) 100%);
  border-radius: 10px;
  padding: clamp(30px, 4vw, 65px);
  display: flex;
  align-items: center;
  .imgHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 170px;
    min-height: 170px;
    background: linear-gradient(180deg, #7361e3 0%, rgba(30, 21, 82, 0) 100%);
    border-radius: 10px;
    margin-right: clamp(10px, 2vw, 40px);
    img {
      width: 80%;
      border-radius: 12px;
    }
  }
  .details {
    h4 {
      margin-bottom: 14px;
    }
  }
  @media (max-width: 992px) {
    flex-direction: column;
    .imgHolder {
      margin-right: 0;
      margin-bottom: 40px;
    }
  }
`;
